import React, { useState, useEffect } from "react"
import { Redirect, useHistory } from "react-router"
import styles from "../../scss/components/EntradasLolla/_Form.module.scss"
import Success from "./Success"
import Cookies from "js-cookie"

export default function Form({ success, setSuccess }) {
  let history = useHistory()
  const [data, setData] = useState({
    name: "",
    email: "",
    age: "",
    dni: "",
    city: "",
  })
  // const [selectedInput, setSelectedInput] = useState("")
  const [isChecked, setIsChecked] = useState(false)
  const [validateError, setValidateError] = useState("")

  const handleInputChange = e => {
    let { name, value } = e.target
    if (e.target.validity.valid) {
      setData({
        ...data,
        [name]: value,
      })
    }
  }

  function validateEmail(data) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    // return re.test(String(data.email).toLowerCase())
    const email = data.email
    return re.test(String(email).toLowerCase())
  }
  function validateForm() {
    if (validateEmail(data)) {
      if (!data.name || !data.dni || !data.city || !data.age) {
        // ACA SI HAY ALGUN CAMPO VACIO
        setValidateError("empty")
      } else if (Number(data.age) < 18 || data.age.length > 2) {
        setValidateError("age")
      } else if (isChecked) {
        // ACA SI TODO ESTA BIEN
        setValidateError("success")

        let user = {
          name: data.name,
          age: data.age,
          email: data.email,
          province: data.city,
          dni: data.dni,
          td: Cookies.get("_td"),
        }

        // https://wabudweiser-prd.azurewebsites.net
        // http://localhost:8080

        fetch("https://wabudweiser-prd.azurewebsites.net/acreditacionlolla", {
          method: "POST",
          body: JSON.stringify(user),
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }).then(response => {
          if (response.ok) {
            setSuccess(true)
          } else {
            setValidateError("duplicated")
            response.text().then(text => {
              console.error("Error guardando los datos. \n", text)
            })
          }
        })
      } else {
        // ACA SI FALTA ACEPTAR LOS TERMINOS Y CONDICIONES
        setValidateError("terms")
      }
    } else {
      // ACA SI EL EMAIL ES INVALIDO
      setValidateError("email")
    }
  }

  const MessageValidate = () => {
    switch (validateError) {
      case "email":
        return <h1 className={styles.error}>EL EMAIL ES INVALIDO</h1>
      case "ageLength":
        return <h1 className={styles.error}>La edad ingresada no es válida</h1>
      case "age":
        return (
          <h1 className={styles.error}>
            Debés ser mayor de 18 años para acreditarte.
          </h1>
        )
      case "empty":
        return <h1 className={styles.error}>DEBE COMPLETAR TODOS LOS CAMPOS</h1>
      case "duplicated":
        return (
          <h1 className={styles.error}>El email ingresado ya está en uso</h1>
        )
      case "terms":
        return (
          <h1 className={styles.error}>
            DEBE ACEPTAR LOS TERMINOS Y CONDICIONES
          </h1>
        )
      case "success":
        return null
      default:
        return null
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    validateForm()
  }
  const CheckboxInput = ({ name, label, isChecked, setIsChecked }) => {
    const toggleCheck = e => {
      setIsChecked(() => !isChecked)
    }
    return (
      <div>
        <input
          type="checkbox"
          className={styles.termsRadio}
          name={name}
          id={name}
          checked={isChecked}
          onChange={toggleCheck}
        />
        <label htmlFor={name}>{/* <span>{label}</span> */}</label>
      </div>
    )
  }
  return (
    <div className={styles.container}>
      <form onSubmit={handleSubmit} className={styles.formContainer}>
        <input
          type="text"
          name="name"
          onChange={handleInputChange}
          className={styles.simpleInput}
          placeholder="Nombre y Apellido"
        />
        <input
          type="text"
          name="dni"
          maxLength={8}
          onChange={handleInputChange}
          className={styles.simpleInput}
          value={data.dni}
          placeholder="DNI"
          pattern="[0-9]*"
        />

        <input
          type="text"
          maxLength={2}
          className={styles.simpleInput}
          placeholder="Edad"
          value={data.age}
          onChange={handleInputChange}
          name="age"
          pattern="[0-9]*"
        />

        <input
          type="email"
          className={styles.simpleInput}
          placeholder="Email"
          onChange={handleInputChange}
          name="email"
        />

        <select
          className={styles.simpleInput}
          onChange={handleInputChange}
          value={data.city}
          name="city"
        >
          <option selected hidden>
            Provincia
          </option>
          <option value="BsAs CABA">BsAs CABA</option>
          <option value="Buenos Aires">Buenos Aires</option>
          <option value="Catamarca">Catamarca</option>
          <option value="Chaco">Chaco</option>
          <option value="Chubut">Chubut</option>
          <option value="Córdoba">Córdoba</option>
          <option value="Corrientes">Corrientes</option>
          <option value="Entre Ríos">Entre Ríos</option>
          <option value="Formosa">Formosa</option>
          <option value="Jujuy">Jujuy</option>
          <option value="La Pampa">La Pampa</option>
          <option value="La Rioja">La Rioja</option>
          <option value="Mendoza">Mendoza</option>
          <option value="Misiones">Misiones</option>
          <option value="Neuquén">Neuquén</option>
          <option value="Río Negro">Río Negro</option>
          <option value="Salta">Salta</option>
          <option value="San Juan">San Juan</option>
          <option value="San Luis">San Luis</option>
          <option value="Santa Cruz">Santa Cruz</option>
          <option value="Santa Fe">Santa Fe</option>
          <option value="Santiago del Estero">Santiago del Estero</option>
          <option value="Tierra del Fuego">Tierra del Fuego</option>
          <option value="Tucumán">Tucumán</option>
        </select>
        {/* FIN DE A COMPONENTE */}
        <br />
        <div className={styles.termsContainer}>
          <CheckboxInput
            name="remember-me"
            label="Remember Me"
            isChecked={isChecked}
            setIsChecked={setIsChecked}
          />
          <h4 className={styles.termsText}>
            He leído y acepto&nbsp;
            <a
              className={styles.link}
              target="_blank"
              href="/BBCC BUD-Lolla QR.docx"
            >
              Bases y Condiciones
            </a>
            ,&nbsp;
            <a
              className={styles.link}
              target="_blank"
              href="/Terminos-Condiciones.pdf"
            >
              Términos y Condiciones
            </a>
            ,&nbsp;
            <a
              className={styles.link}
              target="_blank"
              href="/PoliticadePrivacidad.pdf"
            >
              Políticas de Privacidad
            </a>
            &nbsp; y activaciones de marketing.
          </h4>
        </div>
        {/* TERMS END */}
        {/* SUBMIT START */}
        <div style={{ paddingBottom: "20px", alignSelf: "center" }}>
          <input type="submit" value="Enviar" className={styles.btnSubmit} />
        </div>
        <MessageValidate />
      </form>
    </div>
  )
}
